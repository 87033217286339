import request from '@/utils/request'
const vscode = '/service-vscode-provider/v1/desktop/vscode'
// 获取vscode连接地址
export function getConnection() {
  return request({
    url: vscode + '/connection',
    method: 'get'
  })
}

// 创建vscode服务
export function addConnection() {
  return request({
    url: vscode + '/connection',
    method: 'post'
  })
}
