<template>
  <div v-loading="isLoading" :style="sliderStyle">
    <iframe v-show="isShowVscode" id="vscode" :src="url" :style="sliderStyle" />
    <div v-show="!isShowVscode" class="null-style">
      <div>
        <img src="@/assets/icon/start-vscode.png" alt="">
        <div class="tips">
          <div class="text">服务器还未启动，是否启动</div>
          <el-button type="primary" @click="startHandle">启动</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConnection, addConnection } from '@/api/vscode'
import { ElMessage } from 'element-plus'
export default {
  name: 'VsCode',
  data() {
    return {
      isLoading: true,
      url: '',
      sliderStyle: {
        width: '300px',
        height: '200px'
      },
      loadingSw: true,
      isShowVscode: false,
      psd: ''
    }
  },
  mounted() {
    this.initData()
    const that = this
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    // 创建实例带参
    // const erdUltraFast = elementResizeDetectorMaker({
    //   strategy: 'scroll', // <- For ultra performance.
    //   callOnAdd: true,
    //   debug: true
    // })
    // 监听id为test的元素 大小变化
    const i = document.getElementsByClassName('window-body').length - 1
    erd.listenTo(document.getElementsByClassName('window-body')[i], function(element) {
      var width = element.offsetWidth
      var height = element.offsetHeight
      that.sliderStyle.height = height + 'px'
      that.sliderStyle.width = width + 'px'
    })
  },
  methods: {
    // 初始化判断获取连接地址是否有数据
    initData() {
      getConnection().then((response) => {
        if (response.data != null) {
          this.isShowVscode = true
          this.isLoading = true
          var ishttps = document.location.protocol == 'https:'
          if (ishttps) {
            // alert('https')
            this.url = 'https://' + location.host + '/vscode-proxy/' + window.localStorage.regionName + '/code-server-' + response.data.path + '/login?ew=true&folder=/home/coder/project'
          } else {
            // alert('http')
            this.url = 'http://' + location.host + '/vscode-proxy/' + window.localStorage.regionName + '/code-server-' + response.data.path + '/login?ew=true&folder=/home/coder/project'
          }
          setTimeout(() => {
            const vscode = document.getElementById('vscode')
            const if_doc = vscode.contentWindow.document
            this.isLoading = false
            if_doc.getElementsByClassName('password')[0].value = response.data.password
            if_doc.getElementsByClassName('-button')[0].style.cursor = 'pointer'
            if_doc.getElementsByClassName('-button')[0].click()
          }, 2000)
        } else {
          this.isLoading = false
          this.isShowVscode = false
        }
        console.log(document.getElementById('vscode'), '........')
      })
    },
    // 启动
    startHandle() {
      this.isLoading = true
      addConnection().then((response) => {
        if (response.meta.status === 200) {
          const that = this
          setTimeout(() => {
            that.initData()
          }, 10000)
        } else {
          ElMessage.error(response.meta.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.null-style{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tips{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    .text{
      padding-bottom: 30px;
    }
    .el-button{
      min-height: 34px;
      padding: 0 34px;
      color: #fff;
    }
  }
}
</style>
